import React, {useCallback, useEffect, useRef, useState} from "react";
import DataTable from "react-data-table-component";
import {useHistory, useLocation} from 'react-router-dom';
import Input from "../../components/Input";
import {ReactComponent as SearchIcon} from "../../assets/img/search.svg";
import {ReactComponent as CheckCircle} from "../../assets/img/checkCircle.svg";
import {ReactComponent as CancelCircle} from "../../assets/img/cancelCircle.svg";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar.svg";
import Pagination from "../../components/Pagination";
import {
  CustomerInfoInterface,
  CustomerRequestInterface,
} from "../../types/customer";
import {
  getAllStaffDiscountMembers,
  addStaffDiscountMembersAxios,
  editStaffDiscountMembersAxios,
  removeStaffDiscountMemberAxios,
  getStaffDiscountLoyaltyCardAxios,
  importStaffDiscountWorkersAxios,
  removeBulkStaffDiscountAxios, getMaximumSpendDiscountAxios,
} from "../../api/customers";
import {Wrapper} from "./styled";

import Select from "../../components/Select";
import {useActions} from "../../hooks/useActions";
import {ReactComponent as EditIcon} from "../../assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";
import {useTypedSelector} from "src/hooks/useTypedSelector";
import StaffFamilyTable from "./InnerTable/StaffFamilyTable";
import Modal from "../../components/Modal";
import {REACT_APP_API_URL} from "../../types/env";
import InfoTooltip from "../../components/infoTooltip";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

const arrangeCustomerSelect = [
  { value: "last_name:asc", label: "Sort A-Z" },
  { value: "last_name:desc", label: "Sort Z-A" },
  { value: "id:asc", label: "Sort Oldest → Newest" },
  { value: "id:desc", label: "Sort Newest → Oldest" },
  { value: "last_visit:desc", label: "Check-ins Most Recent → Oldest" },
  { value: "last_visit:asc", label: "Check-ins Oldest → Most Recent" }
];

const StaffDiscount = () => {
  const [staffList, setStaffList] = useState<any>([]);
  const externalIdInp = useRef<HTMLInputElement | null>(null);
  const firstNameInp = useRef<HTMLInputElement | null>(null);
  const lastNameInp = useRef<HTMLInputElement | null>(null);
  const employeeNumberInp = useRef<HTMLInputElement | null>(null);
  const gcNumberInp = useRef<HTMLInputElement | null>(null);
  const {loggedUser} = useTypedSelector((state) => state.auth);
  
  const { search } = useLocation();
  const history = useHistory()
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("code");
  
  const [requestParameters, setRequestParameters] =
    useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: code ? code : null,
      location: "",
      // group: "",
      sort_type: "last_name:asc"
    });
  const [staffListInfo, setStaffListInfo] = useState<CustomerInfoInterface>({
    count_users: 0,
    limit: 0,
    page: 0,
    total_pages_count: 0,
    total_users_count: 0,
  });
  const [maximumSpend, setMaximumSpend] = useState<number>(0)
  const [useMaximumSpendDiscount, setUseMaximumSpendDiscount] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false);
  const [openImportForm, setOpenImportForm] = useState(false);
  const [haveStaffCardModel, setHaveStaffCardModel] = useState(false);
  const [openBulkRemoveForm, setOpenBulkRemoveForm] = useState(false);
  const [fileBase64, setFileBase64] = useState<any>("");
  const { locations } = useTypedSelector(
    (state) => state.groupsLocations
  );
  const [dates, setDatesState] = useState({
    startDate: moment(new Date(2022, 0, 1)),
    endDate: moment()
  });
  const [newUser, setNewUser] = useState<any | null>({
    email: "",
    first_name: "",
    last_name: "",
    external_id: '',
    // phone: "",
    discount: "",
    store_name: loggedUser.user.global_card_name,
    barcode_number: "",
    // code_name: "",
    new: true,
  });
  const [editUser, setEdinUser] = useState<any | null>({
    email: "",
    first_name: "",
    last_name: "",
    // phone: "",
    discount: "",
    external_id: '',
    store_name: loggedUser.user.global_card_name,
    barcode_number: "",
    code_name: "",
  });
  const removeInp = useRef<HTMLInputElement>(null)
  const importInp = useRef<HTMLInputElement>(null)
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  
  const { getGroupsLocations } = useActions();
  
  useEffect(() => {
    getGroupsLocations();
    getMaximumSpendDiscountSetings()
  }, []);
  
  let newStaff = newUser;
  const staffMembersTableColumns = [
    {
      name: "I.D.",
      maxWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="I.D."
                required
                autoComplete="off"
                onInput={(e) => {
                  changeNewStaffValueInput("external_id", e.currentTarget.value);
                }}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="I.D."
                ref={externalIdInp}
                required
                defaultValue={row.external_id}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.external_id
            )}
          </div>
        );
      },
    },{
      name: "First Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="First name"
                required
                autoComplete="off"
                onInput={(e) => {
                  changeNewStaffValueInput("first_name", e.currentTarget.value);
                }}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="First name"
                ref={firstNameInp}
                required
                defaultValue={row.first_name}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.first_name
            )}
          </div>
        );
      },
    },
    {
      name: "Last Name",
      minWidth: "100px",
      // maxWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Last name"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput("last_name", e.currentTarget.value)
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="Last name"
                ref={lastNameInp}
                required
                defaultValue={row.last_name}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.last_name
            )}
          </div>
        );
      },
    },
    {
      name: "Barcode",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.barcode}
          </div>
        );
      },
    },
    {
      name: "Discount percent",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Discount percent"
                required
                type={"number"}
                autoComplete="off"
                min={0}
                max={100}
                onInput={(e) =>
                  +e.currentTarget.value >= 100 ?
                    changeNewStaffValueInput("discount", '100') : +e.currentTarget.value < 0 ? changeNewStaffValueInput("discount", '0') :
                      changeNewStaffValueInput("discount", e.currentTarget.value)
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="Discount percent"
                ref={gcNumberInp}
                required
                type={"number"}
                min={0}
                max={100}
                defaultValue={`${row.discount}`}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.discount
            )}
          </div>
        );
      },
    },
    {
      name: "Used/Maximum Spend Discount",
      omit: !useMaximumSpendDiscount,
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {!!row.used_maximum_spend_discount ? row.used_maximum_spend_discount+'/'+ maximumSpend : '0/'+ maximumSpend}
          </div>
        );
      },
    },
    {
      name: "Discount Issued",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.total_discount ? `${loggedUser?.user?.currency_symbol || '€'}${row.total_discount}`  : `${loggedUser?.user?.currency_symbol || '€'}0`}
          </div>
        );
      },
    },
    {
      name: "Email Address",
      minWidth: "275px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Email Address"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput("email", e.currentTarget.value.trim())
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.email
            )}
          </div>
        );
      },
    },
    {
      name: "Store",
      minWidth: "100px",
      cell: (row: any): JSX.Element => {
        return <div>{loggedUser.user.global_card_name}</div>;
      },
    },
    {
      name: "Activated",
      maxWidth: "50px",
      cell: (row: any): JSX.Element => {
        return <div>
          {row.active ? <div className="active"><CheckCircle/></div> : <div className="not-active"><CancelCircle/></div>}
        </div>;
      },
    },
    {
      name: "",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.new && !row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => {
                    setEdinUser(row);
                    row.edit = true
                  }}
                >
                  <EditIcon/>
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteLocationStaffAsk(row.id)}
                >
                  <DeleteIcon/>
                </button>
              </>
            ) : !row.new && row.edit ? (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => saveStaffMembers(row.id)}
              >
                Save
              </button>
            ) : (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => addNewUserToLocation(row.id)}
              >
                Add
              </button>
            )}
          </div>
        );
      },
    },
  ];
  
  const handleChangeRequestParameters = (
    name: string,
    value: string | number | Date
  ) => {
    setRequestParameters((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };
 
  function saveStaffMembers(id: any) {
    setIsLoading(true);
    let user = {
      id: id,
      email: editUser.email,
      first_name: firstNameInp?.current?.value,
      external_id: externalIdInp?.current?.value,
      last_name: lastNameInp?.current?.value,
      discount: +gcNumberInp?.current!.value >= 100 ? '100' : +gcNumberInp?.current!.value <= 0 ? '0' : gcNumberInp?.current?.value,
    };
    
    editStaffDiscountMembersAxios(user)
      .then((response) => {
        getStaff();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }
  const getMaximumSpendDiscountSetings = ()=>{
    getMaximumSpendDiscountAxios().then(res=>{
      // setMaximumSpendPeriod(res.data.maximumSpendPeriod || 1)
      setMaximumSpend(+res.data.maximumSpendValue || 0)
      setUseMaximumSpendDiscount(res.data.usingMaximumSpend || false)
    })
  }
  
  function changeNewStaffValueInput(label: string, value: string) {
    newStaff = {...newStaff, [label]: value};
  }
  
  useEffect(() => {
    setTimeout(() => {
      if (
        firstNameInp.current &&
        externalIdInp.current &&
        lastNameInp.current &&
        employeeNumberInp.current &&
        gcNumberInp.current
      ) {
        firstNameInp.current.value = editUser.first_name;
        externalIdInp.current.value = editUser.external_id;
        lastNameInp.current.value = editUser.last_name;
        employeeNumberInp.current.value = editUser.employee_number;
        gcNumberInp.current.value = editUser.gc_number;
      }
    }, 500);
  }, [editUser]);
  
  function addStaffMembers() {
    setNewUser(newStaff);
    setStaffList((staffList: any) => (staffList = [newUser, ...staffList]));
  }
  
  function deleteLocationStaffAsk(row: any) {
    setIsLoading(true);
    removeStaffDiscountMemberAxios({id: row})
      .then((response) => {
        getStaff();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }
  
  const getStaff = useCallback(() => {
    setIsLoading(true);
    getStaffDiscountLoyaltyCardAxios().then(res=>{
      res.data.loyalty_card.staff_barcode_prefix ? setHaveStaffCardModel(true) : setHaveStaffCardModel(false)
    })
    getAllStaffDiscountMembers({
      ...requestParameters,
      startDate: moment(dates.startDate).format("DD-MM-yyyy"),
      endDate: moment(dates.endDate).format( "DD-MM-yyyy")
    })
      .then((response) => {
        setStaffList(response.data.workers);
        
        setStaffListInfo({
          count_users: Number(response.data.count_workers),
          limit: Number(response.data.limit),
          page: Number(response.data.page),
          total_pages_count: Number(response.data.total_pages_count),
          total_users_count: Number(response.data.total_workers_count),
          // searchType: Number(response.data.searchType),
        });
        
        const reqDate = dates.startDate
        if(response.data.startDate !== reqDate.format( 'yyyy-MM-DD')){
          setDatesState({...dates, startDate: moment(new Date(response.data.startDate))});
        }
        setIsLoading(false);
        if (searchParams.has('code')) {
          searchParams.delete('code')
          history.replace({
            search: searchParams.toString(),
          })
        }
      })
      .catch(() => setIsLoading(false));
  },[dates, requestParameters]);
  
  useEffect(() => {
    getStaff();
  }, [requestParameters.page, requestParameters.location, requestParameters.sort_type, dates]);
  
  useEffect(() => {
    if (requestParameters.search !== null) {
      const timeout = setTimeout(() => {
        getStaff();
      }, 500);
      
      return () => clearTimeout(timeout);
    }
  }, [requestParameters.search]);
  
  useEffect(() => {
    if (Number(requestParameters.page) === 1 && staffListInfo.limit !== 0) {
      getStaff();
    }
  }, [requestParameters.limit]);
  
  const addNewUserToLocation = (id: any) => {
    setIsLoading(true);
    newStaff = {...newStaff, new: false};
    addStaffDiscountMembersAxios(newStaff)
      .then((response) => {
        getStaff();
        setIsLoading(false);
        newStaff = newUser;
      })
      .catch(() => setIsLoading(false));
  };
 
  const getBase64 = (e: any) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileBase64(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  
  const handleImportStaffDiscountWorkers = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenImportForm(false);
    importStaffDiscountWorkersAxios({
      file: fileBase64,
      // @ts-ignore
      name: importInp!.current?.files[0].name,
    })
      .finally(() => {
        setIsLoading(false);
        getStaff();
        setFileBase64(null)
      });
  };
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment(),
      endDate: moment()
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate,
      endDate: moment()
    }) : setDatesState({
      startDate: startDate,
      endDate: endDate
    });
  };
  
  const handleBulkRemove = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenBulkRemoveForm(false);
   
    removeBulkStaffDiscountAxios({
      file: fileBase64,
      // @ts-ignore
      name: removeInp!.current?.files[0].name,
    })
      .finally(() => {
        setIsLoading(false);
        getStaff();
        setFileBase64(null)
      });
  };
  
  return (
    <Wrapper>
      <div className="page-title">Staff members</div>
      <div className="top-bar staff-top-bar">
        <div className="buttons-container">
          <div className="search-wrap">
            <Input
              placeholder="Search..."
              defaultValue={requestParameters.search ? requestParameters.search : ''}
              required
              icon={<SearchIcon/>}
              getValue={(value) =>
                setRequestParameters({...requestParameters, search: value})
              }
            />
          </div>
          <button
            type="button"
            className="btn-white staff-add-btn"
            disabled={!haveStaffCardModel}
            style={{marginBottom: "12px"}}
            onClick={() => addStaffMembers()}
          >
            Add New Member
            {!haveStaffCardModel ?
              <InfoTooltip infoText={'To add a member, first configure the Staff card model!'}/> : null}
          </button>
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={() => setOpenImportForm(!openImportForm)}
          >
            Import from File
          </button>
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}
          >
            Bulk Remove
          </button>
        </div>
        
        <div className="filter-container">
          {/*<div className="groups-wrap">*/}
          {/*  <Select*/}
          {/*    placeholder="All Groups"*/}
          {/*    selectorList={groups}*/}
          {/*    disabled={!(groups.length > 0)}*/}
          {/*    positionForShow="bottom"*/}
          {/*    required*/}
          {/*    isClearable*/}
          {/*    getValue={(value) => handleChangeRequestParameters("group", value)}*/}
          {/*    hideSelectorFilter*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="groups-wrap">
            <Select
              placeholder="Arrange Customer List"
              selectorList={arrangeCustomerSelect}
              positionForShow="bottom"
              defaultValue={arrangeCustomerSelect[0]}
              required
              getValue={(value) =>
                handleChangeRequestParameters("sort_type", value)
              }
              hideSelectorFilter
            />
          </div>
          <div className="locations-wrap">
            <Select
              placeholder="All Locations"
              selectorList={locations}
              disabled={!(locations.length > 0)}
              positionForShow="bottom"
              required
              isClearable
              getValue={(value) => handleChangeRequestParameters("location", value)}
              hideSelectorFilter
            />
          </div>
          <div className="calendar-wrap">
            <DateRangePicker
              onApply={setDates}
              initialSettings={{ranges: ranges }}
            >
              <div className={'isSelect select'}>
                <CalendarIcon/>
                <input
                  type="text"
                  value={dates.startDate.format("MMM D, YYYY") + "-" + dates.endDate.format("MMM D, YYYY")}
                  onChange={(e) => {
                    setDates(e, dates)
                  }}
                  className="form-control"
                />
              </div>
            
            </DateRangePicker>
          </div>
        </div>
      </div>
      {openImportForm && (
        <Modal style={{maxWidth: '700px'}} openModal={openImportForm}
               setOpenModal={() => setOpenImportForm(!openImportForm)}>
          <form
            encType="multipart/form-data"
            className={"form-file"}
            onSubmit={(e) => handleImportStaffDiscountWorkers(e)}
          >
            <h3>Import your File</h3>
            <p style={{marginBottom: "30px", textAlign: 'center'}}>
              Please choose a file from your drive to upload to the dashboard in xls format.
            </p>
            <p style={{color: 'red', marginBottom: '5px'}}>Example</p>
            <img style={{marginBottom: '10px', objectFit: 'contain', width: '100%'}}
                 src={REACT_APP_API_URL + "/img/examples/staff_import_example.png"} alt="example"/>
            <p style={{color: 'red', marginBottom: '20px'}}>I.D. Column is not mandatory</p>
            <input
              ref={importInp}
              type="file"
              name="file"
              style={{marginBottom: "20px"}}
              id="file"
              accept=".xlsx"
              onChange={(e: any) => getBase64(e)}
            />
            <div style={{marginTop: "20px"}}>
              <button
                type="button"
                className="btn-red staff-add-btn"
                style={{marginBottom: "12px", marginRight: "20px"}}
                onClick={() => setOpenImportForm(!openImportForm)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-white staff-add-btn"
                disabled={!fileBase64}
                style={{marginBottom: "12px", marginRight: "20px"}}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      )}
      {openBulkRemoveForm && (
        <Modal style={{maxWidth: '700px'}} openModal={openBulkRemoveForm}
               setOpenModal={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}>
          <form
            encType="multipart/form-data"
            className={"form-file"}
            onSubmit={(e) => handleBulkRemove(e)}
          >
            <h3>Import your File</h3>
            <p style={{marginBottom: "30px", textAlign: 'center'}}>
              Please select a file from your drive to remove staff members from dashboard in xls format.
            </p>
            <p style={{color: 'red', marginBottom: '5px'}}>Example</p>
            <img style={{marginBottom: '20px', objectFit: 'contain', width: '100%'}}
                 src={REACT_APP_API_URL + "/img/examples/staff_import_example.png"}
                 alt="example"/>
            <p style={{color: 'red', marginBottom: '20px'}}>I.D. Column is not mandatory</p>
            <input
              type="file"
              name="file"
              ref={removeInp}
              style={{marginBottom: "20px"}}
              id="file"
              accept=".xlsx"
              onChange={(e: any) => getBase64(e)}
            />
            <div style={{marginTop: "20px"}}>
              <button
                type="button"
                className="btn-red staff-add-btn"
                style={{marginBottom: "12px", marginRight: "20px"}}
                onClick={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-white staff-add-btn"
                disabled={!fileBase64}
                style={{marginBottom: "12px", marginRight: "20px"}}
              >
                Remove
              </button>
            </div>
          </form>
        </Modal>
      )}
      <div className={`table-wrap ${isLoading ? "loading-blue" : ""}`}>
        <div className="table">
          <DataTable
            noHeader
            columns={staffMembersTableColumns}
            data={staffList}
            expandableRows
            expandableRowsComponent={
              <StaffFamilyTable data={staffList} getCustomerList={getStaff}/>
            }
          />
          <Pagination
            {...staffListInfo}
            requestLimit={requestParameters.limit}
            setRequestParamtrs={setRequestParameters}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default StaffDiscount;
